<template>
  <div>
    <div
        class="table-responsive"
        v-if="data && data.length > 0 && thead && thead.length > 0"
    >
      <table class="table mb-0">
        <thead>
        <tr>
          <th
              :key="item.key"
              :style="item.sort ? 'cursor: pointer' : ''"
              @click="item.sort ? sortChange(item.key) : false"
              class="table--th-view"
              style="padding-top: 0"
              v-for="item in thead"
          >
              <span class="d-flex align-items-center">
                {{ $t('system.' + item.label) }}
                <div
                    class="d-flex flex-column table-sort-icons"
                    v-if="item.sort"
                >
                  <span v-if="item.key == params.column">
                    <font-awesome-icon
                        :class="params.direction == 'asc' ? 'active' : ''"
                        class="caret-sort caret-sort-up"
                        icon="caret-up"
                    />
                    <font-awesome-icon
                        :class="params.direction == 'desc' ? 'active' : ''"
                        class="caret-sort caret-sort-down"
                        icon="caret-down"
                    />
                  </span>
                  <span v-else><font-awesome-icon
                      class="caret-sort caret-sort-up"
                      icon="caret-up"
                  />
                    <font-awesome-icon
                        class="caret-sort caret-sort-down"
                        icon="caret-down"
                    />
                  </span>
                </div>
              </span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            :key="key"
            v-for="(row, key) in data"
        >
          <td
              :key="key"
              class="table--tbody"
              v-for="(item, key) in thead"
          >
            <div class="d-flex justify-content-center">
              <template v-if="editElement && editElement[idColumn] === row[idColumn]">
                <template v-if="item.type === 'hours'">
                  <el-input
                      :class="[item.class ? item.class : '', item.align ? item.align : '', backendErrors && backendErrors['time_entries.hours'] ? 'validate-error' : '']"
                      maxlength="6"
                      name="hours"
                      size="mini"
                      v-mask="'###:##'"
                      v-model="editElement[item.key]"
                  >
                  </el-input>
                </template>
                <template v-else-if="item.type === 'select'">
                  <el-select
                      filterable
                      size="mini"
                      v-model="editElement[item.target]"
                  >
                    <el-option
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                        v-for="option in eval(item.source)"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-else-if="item.type === 'datetimePicker'">
                  <el-date-picker
                      :clearable="false"
                      size="mini"
                      type="datetime"
                      v-model="editElement[item.key]"
                  >
                  </el-date-picker>
                </template>
                <template v-else-if="item.type === 'actions'">
                  <el-button
                      @click.native="updateElement"
                      size="mini"
                      type="success"
                  >
                    {{ $t('system.save') }}
                  </el-button>
                  <el-button
                      @click.native="closeEditRow"
                      size="mini"
                  >
                    {{ $t('system.cancel') }}
                  </el-button>
                </template>
                <template v-else>
                  <el-input
                      :class="[item.class ? item.class : '', item.align ? item.align : '', backendErrors && backendErrors[item.key] ? 'validate-error' : '']"
                      :type="item.type ? item.type : ''"
                      autosize
                      size="mini"
                      v-model="editElement[item.key]"
                  ></el-input>
                </template>
              </template>
              <template v-else>
                <template v-if="item.type === 'hours'">
                  {{ secondsToHHHmm(time2sec(row[item.key])) }}
                </template>
                <template v-else-if="item.type === 'actions'">
                  <el-button
                      @click.native="editRow(row)"
                      size="mini"
                      type="info"
                      v-if="(item.canEdit && !row['time_entries.settlement_id']) || ifHasPermission(['pm-admin'])"
                  >
                    {{ $t('system.edit') }}
                  </el-button>
                  <el-button
                      @click.native="removeRow(row[idColumn])"
                      size="mini"
                      type="danger"
                      v-if="(item.canRemove && !row['time_entries.settlement_id']) || ifHasPermission(['pm-admin'])"
                  >
                    {{ $t('system.delete') }}
                  </el-button>
                </template>
                <template v-else>
                  <div
                      :class="item.type === 'textarea' ? 'align-left' : ''"
                      v-html="row[item.key]"
                  ></div>
                </template>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="thead && thead.length === 0">
      <span class="no-date-title">{{ $t('system.no_selected_column') }}</span>
    </div>
    <div v-if="data && data.length === 0 && thead && thead.length > 0">
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
    <el-card
        class="pagination-container"
        shadow="never"
        v-if="total > 10 && thead && thead.length > 0"
    >
      <el-pagination
          :current-page="params.page"
          :page-size="params.per_page"
          :page-sizes="pageSizes"
          :pager-count="5"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
_
<script>
import _ from 'lodash';
import swal from 'sweetalert2';
import time from '../../mixin/time';

export default {
  name: 'timeEntryBlock',
  mixins: [time],
  props: {
    total: {
      type: Number,
    },
    idColumn: {
      type: String,
      default: 'time_entries.id',
    },
    params: {
      type: Object,
    },
    thead: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      backendErrors: [],
      editElement: null,
      pageSizes: [20, 30, 50, 100],
    };
  },
  beforeMount() {
    if (this.ifHasPermission(['pm-edit-time-logs']) ||
        this.ifHasPermission(['pm-edit-own-time-logs']) ||
        this.ifHasPermission(['pm-remove-time-logger'])) {
      this.thead.push({
        label: 'actions',
        key: 'actions',
        sort: false,
        type: 'actions',
        canEdit: this.ifHasPermission(['pm-edit-own-time-logs']) ||
            this.ifHasPermission(['pm-edit-time-logs']),
        canRemove: this.ifHasPermission(['pm-remove-time-logger']),
      });
    }
  },
  methods: {
    eval(val) {
      return this[val];
    },
    closeEditRow() {
      this.editElement = null;
      this.backendErrors = [];
    },
    updateElement() {
      let result = new Promise((resolve, reject) => this.$emit('updateElement', this.editElement, resolve, reject));
      result.then(() => {
        this.closeEditRow();
      }).catch((err) => {
        this.backendErrors = err;
      });
    },
    editRow(row) {
      this.editElement = _.clone(row);
      if (this.editElement['time_entries.hours']) {
        this.editElement['time_entries.hours'] = this.secondsToHHHmm(this.time2sec(this.editElement['time_entries.hours']));
      }
    },
    removeRow(id) {
      swal.fire({
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      }).then((result) => {
        if (result.value) {
          this.$emit('removeElement', id);
        }
      });
    },
    refresh() {
      this.$emit('refresh');
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.refresh();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.refresh();
    },
    next() {
      this.params.page++;
      this.refresh();
    },
    prev() {
      this.params.page--;
      this.refresh();
    },
    sortChange(column) {
      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.$emit('update:params', this.params);
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import './../../static/css/table';

.align-left {
  white-space: pre;
  text-align: left;
  width: 100%;
  line-height: 17px
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 190px;
}
</style>
