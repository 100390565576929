<template>
  <base-form-item :field="field">
    <template slot="label">
      <div
          class="d-inline-block"
          style="font-weight: normal"
      >
        <el-tooltip :content="$t('system.worked_time')">
          <el-tag
              class="mr-2 ml-2 tag-rounded"
              color="#007c91"
              effect="dark"
              size="small"
          >
            {{ secondsToHHHmm(allTimesSum) }}
          </el-tag>
        </el-tooltip>
        <el-tooltip :content="$t('system.remaining_time')">
          <el-tag
              :color="remainingTime.startsWith('-') ? '#b71c1c' : '#4caf50'"
              class="tag-rounded"
              effect="dark"
              size="small"
          >
            {{ remainingTime }}
          </el-tag>
        </el-tooltip>
      </div>
    </template>
    <time-entry-table
        :activities="dictionaries[field.dictionary]"
        :data="model"
        :params.sync="syncParams"
        :thead="thead"
        :total="total"
        :users="dictionaries.users"
        @refresh="fetchData"
        @removeElement="removeElement"
        @updateElement="updateElement"
        class="w-100"
        idColumn="time_entries.id"
    ></time-entry-table>
  </base-form-item>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import BaseFormItem from '../../UI/FormItem.vue';
import TimeEntryTable from '../../../../Table/TimeEntry.vue';
import abstractForm from '../../mixin/index';
import notification from '../../../../../notification/notify';
import EventBus from '../../../../../eventBus';
import time from '../../../../../mixin/time';
import {HHHmmToHHmmss} from '../../../../../helpers/time';

export default {
  name: 'FieldTimeEntryTable_index',
  mixins: [abstractForm, time],
  data() {
    return {
      allTimesSum: 0,
      loading: false,
      model: [],
      total: 0,
      params: {
        column: 'time_entries.spent_on',
        direction: 'desc',
        page: 1,
        per_page: 20,
      },
      thead: [
        {
          label: 'time_entry_spent_on',
          key: 'time_entries.spent_on',
          sort: true,
          type: 'datetimePicker',
        }, {
          label: 'time_entry_users',
          key: 'time_entry_users.name',
          sort: true,
          source: 'users',
          target: 'time_entry_users.id',
          type: 'select',
        }, {
          label: 'time_entry_activity',
          key: 'enumeration_time_entry_activities.name',
          sort: true,
          source: 'activities',
          target: 'enumeration_time_entry_activities.id',
          type: 'select',
        }, {
          label: 'time_entry_hours',
          key: 'time_entries.hours',
          sort: true,
          type: 'hours',
        }, {
          label: 'time_entry_comment',
          key: 'time_entries.comments',
          sort: true,
          type: 'textarea',
        },
      ],
    };
  },
  components: {
    TimeEntryTable,
    BaseFormItem,
  },
  created() {
    EventBus.$on('FETCH_TIME_ENTRIES', () => {
      this.fetchData();
    });
  },
  beforeMount() {
    this.fetchData();
  },
  beforeDestroy() {
    EventBus.$off('FETCH_TIME_ENTRIES');
  },
  computed: {
    remainingTime() {
      let sec = this.time2sec(this.hhhMmToTime(this.data.estimated_hours)) - this.allTimesSum;
      if (sec < 0) {
        sec *= -1;
        return `-${this.secondsToHHHmm(sec)}`;
      }
      return this.secondsToHHHmm(sec);
    },
    syncParams: {
      get() {
        return this.params;
      },
      set(newVal) {
        this.params = newVal;
        this.fetchData();
      },
    },
  },
  methods: {
    async removeElement(id) {
      axios.delete(`time_entry/${id}`)
          .then((response) => {
            this.fetchData();
            notification.notify(
                this.$t('notify.success'),
                this.$t('system.deleted'),
                'success');
          })
          .catch(function (error) {
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    async updateElement(data, resolve, reject) {
      let requiredFields = {};
      const newData = _.mapKeys(data, (item, key) => {
        if (item === '') {
          requiredFields[key] = 'system.required';
        }
        return key.replace('.', '-');
      });
      newData['time_entries-hours'] = HHHmmToHHmmss(newData['time_entries-hours']);
      if (newData['time_entries-hours'] === '00:00:00') {
        requiredFields['time_entries.hours'] = 'system.required';
      }
      if (Object.keys(requiredFields).length) {
        return reject(requiredFields);
      }
      return axios.post(`time_entry/${data['time_entries.id']}`, newData).then((response) => {
        resolve(response);
        this.fetchData();
      }).catch((error) => {
        const newData = _.mapKeys(error.response.data.errors, (item, key) => {
          return key.replace('-', '.');
        });
        reject(newData);
      });
    },
    async fetchData() {
      let columns = _.map(this.thead, 'key');
      columns.push('time_entry_users.id');
      columns.push('enumeration_time_entry_activities.id');
      columns.push('time_entries.settlement_id');
      columns.push('time_entries.id');
      const index = columns.indexOf('actions');
      columns.splice(index, 1);
      return axios.post('time_entries', {
        ...this.params,
        columns: columns,
        params: this.params,
        filters: {
          issuesIds: [this.$route.query.issueId],
        },
      }).then((response) => {
        this.allTimesSum = response.data.allTimesSum;
        // this.model = response.data.data;
        this.model = response.data.data.map(item => {
          if (item['time_entries.spent_on']) {
            const date = moment.utc(item['time_entries.spent_on']);
            item['time_entries.spent_on'] = date.local().format('YYYY-MM-DD HH:mm:ss');
          }
          return item;
        });
        this.total = response.data.total;
      });
    },
  },
};
</script>

<style scoped>
.summary-time {
  border-radius: 6px;
  background: #e6e6e6;
  line-height: 30px
}
</style>
