import axios from 'axios';

export default {
  methods: {
    secondsToHHHmm(seconds) {
      const sec_num = parseInt(seconds, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

      if (hours < 10) {
        hours = '00' + hours;
      } else if (hours < 100) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      return hours + ':' + minutes;
    },
    convertSecondsToHours(seconds) {
      if (seconds) {
        return this.secondsToHHHmm(seconds);
      }
    },
    time2sec(time) {
      if (time) {
        return time.split(':').reduce((acc, time) => (60 * acc) + +time);
      } else {
        return '';
      }
    },
    hhhMmToTime(time) {
      const timeArray = time.split(':');
      let hours = parseInt(timeArray[0]);
      let minutes = parseInt(timeArray[1]);
      if (minutes > 59) {
        hours += 1;
        minutes -= 60;
      }
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      return hours + ':' + minutes + ':00';
    },
    checkHHHmmFormat(str) {
      const regex = RegExp('([0-9]){3}:([0-9]){2}');
      return regex.test(str);
    },
    async getTimeEntriesSum(ids) {
      return axios.post('time_entries/sum_times', {
        ids: ids,
      });
    },
  },
};
